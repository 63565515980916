@font-face {
  font-family: 'CuprumRegular';
  src: url(../fonts/English/Cuprum-Regular.ttf);
}

@font-face {
  font-family: 'CuprumMedium';
  src: url(../fonts/English/Cuprum-Medium.ttf);
}

@font-face {
  font-family: 'Cuprumsemi';
  src: url(../fonts/English/Cuprum-SemiBold.ttf);
}

@font-face {
  font-family: 'Raber';
  src: local('Raber'), url(../fonts/Arabic/Rabar_014.ttf) format('truetype');
}

@font-face {
  font-family: 'Speda-Bold';
  src: url(../fonts/Arabic/Speda-Bold.ttf);
}
@font-face {
  font-family: 'k24';
  src: url(../fonts/Arabic/K24KurdishLight-Light.ttf);
}

@font-face {
  font-family: 'kurdish_regular';
  src: url('../fonts/Kurdish/kurdish_regular.ttf');
}

@font-face {
  font-family: 'kurdish_bold';
  src: url('../fonts/Kurdish/kurdish_bold.ttf');
}

@font-face {
  font-family: 'arabic_bold';
  src: url(../fonts/Arabic/arabic_bold.ttf);
}

@font-face {
  font-family: 'arabic_semi_bold';
  src: url(../fonts/Arabic/arabic_semi_bold.ttf);
}

@font-face {
  font-family: 'arabic_regular';
  src: url(../fonts/Arabic/arabic_regular.ttf);
}

@font-face {
  font-family: 'UniQAIDAR_Blawkrawe';
  src: local('UniQAIDAR_Blawkrawe'),
    url(../fonts/Arabic/UniQAIDAR_Blawkrawe.ttf) format('truetype');
}

/* Globaly applied styles */
html,
body {
  font-family: 'CuprumRegular', sans-serif;
}

html.rtl,
.rtl body {
  direction: rtl;
  font-family: 'kurdish_regular' !important;
}

p {
  margin: 0;
}

.main-wrapper[lang='en'] {
  font-family: 'CuprumRegular', sans-serif;
}

.main-wrapper[lang='ar'] {
  font-family: 'arabic_regular' !important;
}

.main-wrapper[lang='ba'],
.main-wrapper[lang='kr'] {
  font-family: 'kurdish_regular' !important;
}

.pointer {
  cursor: pointer;
}

.center {
  text-align: center;
}

.priority {
  position: relative;
  z-index: 1;
}

.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #00a981 !important;
  border-radius: 10px;
}
/* End of globaly applied styles */

/* login page styles */
.login-bg {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80% 50%;
}

.login_container {
  margin: auto;
  max-width: 500px;
  width: calc(100% - 200px);
}

.login_inputs {
  padding: 15px 8px;
  border: 1px solid #dddfe1;
  border-radius: 10px;
}

.login_developed_by {
  position: absolute;
  right: 0;
  bottom: 3rem;
  width: 50%;
  text-align: center;
}

html.rtl .login_developed_by {
  right: unset;
  left: 0;
}

.login_phone_input input {
  padding: 27.5px 11px;
  direction: ltr !important;
}
/* end of login page styles */

/* Input with add on before style  */
.Input_with_addBefores input {
  line-height: 38px;
  align-items: center;
  height: 40px !important;
  background: #f9fafc80 !important;
  border-radius: 10px !important;
}

.Input_with_addBefores .ant-input-group-addon {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.Input_with_addBefores .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}
/* End of input with add on before style */

/* start of modal styles */
.modal_style .ant-modal-content {
  border-radius: 10px;
}

.modal_style .ant-modal-content .ant-modal-title {
  font-size: 18px;
  font-family: 'kurdish_bold' !important;
}

.modal_style .ant-modal-content .ant-modal-body {
  font-size: 14px;
  font-family: 'kurdish_bold' !important;
}
/* end of modal styles */

.ant-layout-content .site-layout-background {
  background: #f0f2f5 !important;
}

.main-wrapper[lang='ar'] .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.main-wrapper[lang='ar']
  .ant-menu-sub.ant-menu-inline
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  font-family: 'arabic_semi_bold' !important;
}

/* start of table styles */
.ant-table {
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
}

.ant-table-thead > tr > th {
  border-bottom: none !important;
  height: 62px !important;
  font-family: 'UniQAIDAR_Blawkrawe' !important;
}

.ant-table-tbody > tr > td {
  border-bottom: none !important;
  height: 75px !important;
}

.ant-table-tbody tr:nth-child(even) {
  background-color: #f7f9fb !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 10px !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 10px !important;
}

.main-wrapper[lang='en']
  .ant-table-container
  table
  > tbody
  > tr:last-child
  td:first-child {
  border-bottom-left-radius: 10px !important;
}

.main-wrapper[lang='en']
  .ant-table-container
  table
  > tbody
  > tr:last-child
  td:last-child {
  border-bottom-right-radius: 10px !important;
}

/* .ant-table-container table > tbody > tr:hover {
  box-shadow: 10px 10px 20px lightgray;
} */

/* .ant-table-tbody > tr {
  transition: all 0.3s;
} */
/* .ant-table-tbody > tr:hover {
  transform: scale(1.02);
  border-radius: 50px;
} */

.ant-table-container table > tbody > tr:hover:nth-child(odd) td {
  background-color: #ffffff !important;
}

/* border radius for table ku/ar */
.main-wrapper[lang='ba']
  .ant-table-container
  table
  > tbody
  > tr:hover
  td:first-child,
.main-wrapper[lang='sr']
  .ant-table-container
  table
  > tbody
  > tr:hover
  td:first-child,
.main-wrapper[lang='ar']
  .ant-table-container
  table
  > tbody
  > tr:hover
  td:first-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.main-wrapper[lang='sr']
  .ant-table-container
  table
  > tbody
  > tr:hover
  td:last-child,
.main-wrapper[lang='ar']
  .ant-table-container
  table
  > tbody
  > tr:hover
  td:last-child,
.main-wrapper[lang='ba']
  .ant-table-container
  table
  > tbody
  > tr:hover
  td:last-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

/* border radius for table en */
.main-wrapper[lang='en']
  .ant-table-container
  table
  > tbody
  > tr:hover
  td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.main-wrapper[lang='en']
  .ant-table-container
  table
  > tbody
  > tr:hover
  td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ant-table-tbody > tr > td {
  border-left: thin solid #e2e2e2;
  border-right: thin solid #e2e2e2;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background: none !important;
  border-radius: 10px !important;
  padding: 0px 10px !important;
}

.ant-pagination-item {
  background: none !important;
  border: none !important;
}

.tb_row {
  font-size: 16px;
}

.ant-pagination-options .ant-pagination-options-quick-jumper > input {
  background: none !important;
  border-radius: 10px !important;
}

.ant-pagination-options .ant-select-show-arrow .ant-select-selector {
  background: none !important;
  border-radius: 10px !important;
}

.pager-Info {
  position: relative;
  top: -3.7rem;
  padding-left: 8px;
  max-width: 200px;
}

.main-wrapper[lang='sr'] .pager-Info {
  padding-right: 10px;
  padding-top: 10px;
}
/* end of table styles */

/* start of table styles RTL*/
.ant-table-rtl {
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
}

.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  border-bottom: none !important;
  height: 62px !important;
  font-size: 16px;
}

.main-wrapper[lang='ar'] .ant-table-wrapper-rtl .ant-table-thead > tr > th {
  font-family: 'arabic_bold' !important;
}

.main-wrapper[lang='ar'] .ant-table-wrapper-rtl .ant-table-tbody > tr > td {
  font-family: 'arabic_semi_bold' !important;
}

.main-wrapper[lang='ba'] .ant-table-wrapper-rtl .ant-table-thead > tr > th,
.main-wrapper[lang='sr'] .ant-table-wrapper-rtl .ant-table-thead > tr > th {
  font-family: 'kurdish_bold' !important;
}

.ant-table-wrapper-rtl .ant-table-tbody > tr > td {
  border-bottom: none !important;
  height: 75px !important;
}

.ant-table-thead > tr > th {
  color: #193d3b;
}

.ant-table-tbody > tr > td div {
  color: #193d3b;
}

.main-wrapper[lang='sr'] .ant-table-wrapper-rtl .ant-table-tbody > tr > td div {
  font-family: 'kurdish_bold';
}
.main-wrapper[lang='ba'] .ant-table-wrapper-rtl .ant-table-tbody > tr > td div {
  font-family: 'kurdish_bold';
}

.ant-table-wrapper-rtl .ant-table-tbody tr:nth-child(even) {
  background-color: #f7f9fb !important;
}

.ant-table-wrapper-rtl
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-right-radius: 10px !important;
}

.ant-table-wrapper-rtl
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-left-radius: 10px !important;
}

.ant-table-wrapper-rtl
  .ant-table-container
  table
  > tbody
  > tr:last-child
  td:first-child {
  border-bottom-right-radius: 10px !important;
}

.ant-table-wrapper-rtl
  .ant-table-container
  table
  > tbody
  > tr:last-child
  td:last-child {
  border-bottom-left-radius: 10px !important;
}
/* end of table styles RTL */

.ant-drawer-body {
  background: #f0f2f5 !important;
}

.actionMenu .anticon {
  margin-right: 0 !important;
}

.actionMenu .ant-btn-rtl .anticon {
  margin-left: 0 !important;
}

.ant-popover-inner {
  border-radius: 10px;
}

.bizcharts > div .g2-html-annotation:nth-child(3)::after {
  content: '$';
}

/* New Login Page Styles */

.login-card {
  width: 100%;
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
}

.login-card-title {
  font-family: 'Cuprumsemi';
  font-size: 28px;
  padding: 0;
  margin: 0;
}

.login-card-subtitle {
  font-family: 'CuprumMedium';
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.font-class {
  font-family: 'UniQAIDAR_Blawkrawe' !important;
}

.rtl .font-calss {
  font-family: 'kurdish_bold' !important;
}

.br-10_h-60 {
  border-radius: 10px !important;
  height: 60px !important;
  align-items: center;
}
/* New Login Page Styles */

/*collapse container */

.collapse-container {
  overflow: hidden !important;
  border-radius: 10px !important;
  background-color: #fff !important;
}

.panel_container {
  font-size: 18px !important;
  font-family: 'kurdish_bold' !important;
}

.collapse_content_container {
  font-size: 14px !important;
  font-family: 'kurdish_bold' !important;
}

.panel_container .ant-collapse-content {
  position: relative !important;
}

.panel_container .ant-collapse-content::before {
  content: '';
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 4px !important;
  height: 100% !important;
  background: #65dfc2 !important;
}

/* end of collapse container */

/* number input handler */
/******************************/
/* end of number input handler */

.br-10_h-40 {
  border-radius: 10px !important;
  height: 40px !important;
  align-items: center !important;
}

.ant-drawer-title {
  font-family: 'kurdish_bold';
}

/*  */
html.rtl .ant-picker,
html.rtl .ant-picker-dropdown {
  font-family: 'Cuprumsemi';
}

html.rtl .ant-picker-input > input::placeholder {
  font-family: 'kurdish_regular';
}

.ant-table-measure-row {
  height: 0px;
  position: absolute;
}

.login_page {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login_image {
  width: 400px;
}

.login_image img {
  width: 100%;
}

.phone_submit_btn {
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #fafafa;
  font-family: "SemiBold";
  background: #52c1c4;
  border-radius: 6px;
  height: 45px;
  border: none;
  cursor: pointer;
  @include transition-all-03s;
}

.phone_submit_btn:disabled {
  background: #c7ced5;
  cursor: not-allowed;
}

form.otp_form_login {
  height: 165px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 8px;
  border: thin solid #dbdbdd;
}

form.otp_form_login div {
  display: flex;
  width: 240px;
  align-items: center;
  justify-content: space-between;
}

form.otp_form_login div .code-input {
  padding: 0;
  margin: 0;
  width: 30px;
  height: 45px;
  border: none;
  text-align: center;
  border-bottom: 2px solid #7d7d7e47;
  font-size: 18px;
  color: #57636f;
  line-height: 17px;
  font-family: "Regular";
}

input[type="submit"] {
  margin: 0;
  padding: 0;
  width: 280px;
  height: 45px;
  border: none;
  color: #ffffff;
  border-radius: 8px;
  background-color: #52c1c4;
  cursor: pointer;
}

.resend_otp {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #57636f;
  font-size: 14px;
}

button.ant-btn-primary{
  background-color: #4fc2c5;
}

.ant-carousel .slick-dots li.slick-active button{
  background-color: #4fc2c5 !important;
}

.ant-carousel .slick-dots li button{
  background-color: #005ac5 !important;
}
