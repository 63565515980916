.ant-avatar.ant-avatar-icon {
  font-size: 14px;
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-footer {
  padding: 8px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #ffffff;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

.ant-menu-submenu > .ant-menu {
  background-color: #f4f5f7;
}

.layout_sider {
  height: 100vh;
  left: 0;
  position: fixed;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s;
  background: #ffffff;
  box-shadow: 2px 0px 15px -3px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: 2px 0px 15px -3px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 2px 0px 15px -3px rgba(0, 0, 0, 0.49);
}

/* .sider_menu_links.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #65dfc21a;
}

.sider_menu_links .ant-menu-item:hover {
  background-color: #65dfc21a;
} */

.sider_menu_links span {
  color: #193d3b;
  font-size: 16px;
}

.main-wrapper[lang='ar'] .sider_menu_links span {
  font-family: 'arabic_semi_bold' !important;
}

.main-wrapper[lang='sr'] .sider_menu_links span,
.main-wrapper[lang='ba'] .sider_menu_links span {
  font-family: 'kurdish_bold' !important;
  font-size: 14px;
}

/* .sider_menu_links .ant-menu-item-selected::before {
  content: '';
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #65dfc2;
} */

.logo {
  padding: 13px 0;
  text-align: center;
  margin-bottom: 16px;
}

.logo_small {
  padding: 20px 0;
  text-align: center;
  margin-bottom: 16px;
}

.sider_toggler_btn {
  position: absolute;
  top: 0;
  left: 8px;
  z-index: 2;
}

.main_header_container {
  width: calc(100% - 50px);
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section_title {
  font-size: 18px;
  margin-left: 20px;
}

html.rtl .section_title {
  font-family: 'kurdish_bold';
}

.header_avatar {
  width: calc(100% - 250px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
}

.header_avatar > .bg-img {
  width: 35px;
  height: 35px;
}

.header_display_name {
  color: #1e2832;
  font-size: 14px;
  font-family: 'Speda-Bold';
  text-transform: capitalize;
}

/* sider scroll styles */
.layout_sider::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.layout_sider::-webkit-scrollbar-track {
  background: #fff;
  display: none;
}

/* Handle */
.layout_sider::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}
/* end of sider scroll styles */

/* notification in sider bar */
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  color: #fff;
}
